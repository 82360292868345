import { useEffect, useReducer, useState } from 'react';
import Styles from '../Styles.module.css';
import { baseURL, storage, toaster, user } from '../../functions/O3developer';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import PageTopBar from '../../components/PageTopBar';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom';
import { loadingReducer } from '../../reducers/loading-reducer';
import error from '../../json/error.json';
import PulseLoader from "react-spinners/PulseLoader";

function Opay() {
    const navigate = useNavigate();

    const [amount, setAmount] = useState('');
    const [loading, dispatch] = useReducer(loadingReducer, false);  
    const [gateway, setGateway] = useState('');
    const [opay, setOpay]   = useState([]);
    const [loadingRequest, dispatchRequest]   = useReducer(loadingReducer, false); 

    async function proceed(e) {
        e.preventDefault();        
        if (amount == "") return toaster("error", "Please input an amount");
        if (gateway == "") return toaster("error", "Please select a payment method");
            var endpoint = baseURL+"/fund-wallet/opay-payment";
            // console.log(gateway);
        if (!loadingRequest){
            dispatchRequest({type: 'start'});   
            axios.defaults.headers.common = {'Authorization': `Bearer ${user().token}`}
            await axios.post(endpoint,  {
                amount: amount,
                gateway: gateway,
          }).then((response) => {
            dispatchRequest({type: 'stop'});
            if (response.data.status == "failed" && response.data.message !== "") {
                return toaster("error", response.data.message);
              } else if (response.data.status == "success") {
                      window.location.href = response.data.checkout;
                  } else {
                    return toaster("error", "Wallet topup failed please try again");    
                  }
      
            }).catch((e)=>{
                if (e.response !== undefined) {
                    if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                        storage("remove", "data", null, "localStorage");
                        return navigate("/login");
                    }
                  }
                    return toaster("error", error.loading.excemption);
            }) 
        }       
    }




    async function load() {
        dispatch({type: 'start'});              
          await axios.post(baseURL + "/fund-wallet/opay",  {
          }, {    
            headers:{
                'Authorization':`Bearer ${user().token}`,
                'Content-Type': 'application/json'
            }, 
          }).then((response) => {
              // console.log(response.data);
          if (response.data.status == "failed" && response.data.message !== "") {
            return toaster("error", response.data.message);
          } else if (response.data.status == "success") {          
                setOpay(response.data.opay);
                dispatch({type: 'stop'});
          } else {
            return toaster("error", error.loading.excemption);    
          }
  
          }).catch((e)=>{
            if (e.response !== undefined) {
              if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                  storage("remove", "data", null, "localStorage");
                  return navigate("/login");
              }
            }
              return toaster("error", error.loading.excemption);
          })
      }
  
  
      useEffect(() => { load(); return () => axios.CancelToken.source(); }, []);


    return (
      <div>
  
  
      <PageTopBar title="Opay Funding" />

  {/* <div className="mb-5"></div> */}

  
  <div className="page-title-clear"></div>
    <div className="page-content">


  <ToastContainer /> 

  <div className="card dashboard-layout card-style">
<div className="content mb-0">
<h3> Opay

<span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '8px', borderRadius: '50%'}}>
      <img src="/dashboard/images/opay-logo.png" className="text-center" width="39" style={{  borderRadius: "50%"  }} />
      </span></h3>
<p className={ Styles.description_txt }>
    Enter amount you wish to fund your wallet and select any of the opay payment method.
</p>



<div hidden={!loading}> <SkeletonTheme height={40}> <Skeleton count={2}/> </SkeletonTheme> </div>



<div hidden={loading}>

<div className="input-style input-style-always-active has-borders has-icon validate-field mb-4">
<i className="fas fa-arrow-right" style={{ color: 'green' }}></i>
<input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} min="0" max="999999" className="form-control validate-name shadow-bg-m shadow-s" id="form1ab" placeholder="Amount" />
{/* <label for="form1ab" className="color-theme opacity-50 text-uppercase font-700 font-10">Amount</label> */}
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>



<a href={void(0)}  style={{ boxShadow: "0 4px 24px 0 rgb(0 0 0 / 10%)" }} hidden={opay.status == "available" && amount !== '' ? false : true} onClick={() => setGateway("opay-wallet")} className="card card-style mx-0 mb-3">
<div className="content mb-2 mt-2">
<div className="d-flex">
<div className="ms-1">
<div className="form-check icon-check mt-2">
<input className="form-check-input" type="checkbox" value="owallet" id="check1" onChange={null} checked={gateway == "opay-wallet" ? true : false} />
<i className="icon-check-1 fa fa-circle color-gray-dark font-20"></i>
<i className="icon-check-2 fa fa-check-circle font-20 color-highlight"></i>
</div>
</div>
<div className="ms-5">
<h4 className="mb-n1 font-15">Opay Wallet</h4>
<p className="color-highlight font-11"> Marchant charge </p>
</div>
<div className="ms-auto">
<h3 className="mb-n1 font-15"> ₦{amount} </h3>
<p className="opacity-90 font-11 text-end" style={{ color: "red" }}>+₦{opay.charge}</p>
</div>
</div>
</div>
</a>



{/* <a href={void(0)} className="card card-style mx-0 mb-3" hidden={opay.status == "available" && amount !== '' ? false : true} onClick={() => setGateway("bank-transfer")} style={{ boxShadow: "0 4px 24px 0 rgb(0 0 0 / 10%)" }}>
<div className="content mb-2 mt-2">
<div className="d-flex">
<div className="ms-1">
<div className="form-check icon-check mt-2">
<input className="form-check-input" type="checkbox" value="banktrsf" id="check1" onChange={null} checked={gateway == "bank-transfer" ? true : false} />
<i className="icon-check-1 fa fa-circle color-gray-dark font-20"></i>
<i className="icon-check-2 fa fa-check-circle font-20 color-highlight"></i>
</div>
</div>
<div className="ms-5">
<h4 className="mb-n1 font-15">Bank Transfer</h4>
<p className="color-highlight font-11"> Marchant charge </p>
</div>
<div className="ms-auto">
<h3 className="mb-n1 font-15"> ₦{amount} </h3>
<p className="opacity-90 font-11 text-end" style={{ color: "red" }}>+₦{opay.charge}</p>
</div>
</div>
</div>
</a> */}



{/* <a href={void(0)} className="card card-style mx-0 mb-3" hidden={opay.status == "available" && amount !== '' ? false : true} onClick={() => setGateway("atm")} style={{ boxShadow: "0 4px 24px 0 rgb(0 0 0 / 10%)" }}>
<div className="content mb-2 mt-2">
<div className="d-flex">
<div className="ms-1">
<div className="form-check icon-check mt-2">
<input className="form-check-input" type="checkbox" value="atm" id="check1" onChange={null} checked={gateway == "atm" ? true : false} />
<i className="icon-check-1 fa fa-circle color-gray-dark font-20"></i>
<i className="icon-check-2 fa fa-check-circle font-20 color-highlight"></i>
</div>
</div>
<div className="ms-5">
<h4 className="mb-n1 font-15">ATM Card</h4>
<p className="color-highlight font-11"> Marchant charge </p>
</div>
<div className="ms-auto">
<h3 className="mb-n1 font-15"> ₦{amount} </h3>
<p className="opacity-90 font-11 text-end" style={{ color: "red" }}>+₦{opay.charge}</p>
</div>
</div>
</div>
</a> */}



<a href={void(0)} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s " + Styles.proceedBtn } onClick={proceed} >
    
{ loadingRequest ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
   { loadingRequest ? '' : 'Proceed' }
    
     </a>

    </div>


    <div hidden={!loading} className='text-center mt-2'> <div className="spinner-border text-success" style={{width:"20px", height: "20px"}} role="status"> </div> </div>

<div className='text-center mt-2 mb-3'>
    <img src='/dashboard/images/opay-logo-light.png' width={'70'}/>
</div>

</div>
</div>
  
  
  </div>
      </div>
    )
  
  }
  
  export default Opay;
  